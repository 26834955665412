.link {
   width: 100%;
   text-decoration: none;
   display: flex;
   justify-content: space-between;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.32px;
   cursor: pointer;
}

.innerContainer {
   display: flex;
}

.textInner {
   max-width: 610px;
   font-weight: 600;
   color: #303030;
   font-family: "Inter";
   font-size: 12px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.24px;
}

.title {
   color: #303030;
   font-family: "Inter";
   font-size: 18px;
   font-style: normal;
   font-weight: 500;
   line-height: 20px;
   letter-spacing: -0.28px;
}

.datePublication {
   color: #808080;
   align-self: flex-start;
   text-align: left;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.28px;
}

.documentIcon {
   margin-right: 32px;
   border-radius: 12px;
   padding: 16px;
}

.PDF {
   background: #e13d34;
}

.DOCX {
   background: #307cf1;
}

.XLS {
   background: #36a267;
}

.ARCHIVE {
   background: #e12197;
}

.documentContainer {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.btnType {
   color: #303030;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   border-radius: 40px;
   background: #F6F6F6;
   align-self: flex-start;
   padding: 4px 16px;
}

@media screen and (max-width: 745px) {
   .link {
      width: 100%;
   }

   .datePublication {
      display: none;
   }

   .textInner {
      font-size: 12px;
      line-height: 16px;
   }

   .documentIcon {
      border-radius: 6px;
      padding: 5px;
   }

   .documentContainer {
      border: none;
   }
}