.container {
   margin-top: 40px;
   display: flex;
   justify-content: space-between;
   max-width: 670px;
   gap: 20px;
}

.imgContainer {
   width: 233px;
   height: 230px;
   border-radius: 12px;
   overflow: hidden;
}

.imgContainer img {
   object-fit: cover;
   object-position: 0 -15px;
   width: 100%;
   height: 100%;
}

.textContainer {
   max-width: 377px;
}

.textContainer>*:not(:first-child) {
   margin-top: 14px;
}

.name {
   color: #303030;
   font-size: 18px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.36px;
}

.contact {
   display: flex;
   color: #303030;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: -0.28px;
}

.socialLink {
   display: flex;
}

.imgSocialContainer {
   width: 20px;
   height: 20px;
}

.socialLink *:not(:first-child) {
   margin-left: 16px;
}

.imgSocialContainer img {
   width: 100%;
   height: 100%;
}

.text {
   color: #303030;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: -0.28px;
}

.partyContainer {
   display: flex;
   justify-content: space-between;
   padding-top: 14px;
   border-top: 1px solid #EDEDED;
}

.partyName {
   color: #8291A1;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: -0.28px;
}

.partyLogo {
   height: 20px;
}

.partyLogo img {
   height: 100%;
}

@media screen and (max-width: 780px) {
   .container {
      width: 100%;
      flex-direction: column;
   }

   .contact,
   .text {
      font-size: 13px;
   }
}