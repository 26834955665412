.imgContainer {
   width: 630px;
   height: 420px;
   border-radius: 12px;
   overflow: hidden;
}

.imgContainer img{
   object-fit: cover;
   object-position: center;
   width: 100%;
}

.mainNewsTitle {
   max-width: 580px;
   color: #303030;
   font-size: 32px;
   font-style: normal;
   font-weight: 500;
   line-height: 40px;
   letter-spacing: -0.64px;
}

@media screen and (max-width: 745px) {
   .mainNewsTitle {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
   }
}

@media screen and (max-width: 680px) {
   .imgContainer {
      width: 100%;
      height: 220px;
   }
}