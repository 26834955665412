.tabPage {
   display: none;
}

.tabPage.active {
   display: block;
}

.bold{
   font-size: 18px;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.36px;
}