.bgBlock {
  width: 100%;
}

.logoRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
}

.logoBlock {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #303030;
}

.modilMenu {
  display: none;
}

.textMain {
  margin-left: 13.33px;
  width: 204px;
}

.titleInfo {
  width: 140px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14.12px;
  color: #5c5300;
}

.titleAdministration {
  font-size: 10px;
  font-weight: 600;
  line-height: 14.12px;
}

.settingsContainer {
  display: flex;
  align-items: center;
}

.versionVI img {
  margin-right: 8px;
}

.versionVI {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #8c7e00;
  cursor: pointer;
}

/* .hotLine {
   margin-left: 32px;
   display: flex;
   padding: 8px 16px;
   justify-content: center;
   align-items: center;
   border-radius: 40px;
   background: #FFF;
   border: none;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   text-decoration: none;
   cursor: pointer;
} */

.mobilHotLine {
  display: none;
}

.mainMenuContainer {
  width: 100%;
  /* height: 94px; */
  /*    position: absolute;
   top: 84px; */
  background: #ffffff;
  border-radius: 40px;
}

@media screen and (max-width: 745px) {
  .mainMenuContainer {
    border-radius: 24px;
    height: 20px;
  }

  /* .bgBlock {
      height: 90px;
   } */
}

.mainMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #ededed;
}

.itemsContainer {
  display: flex;
  align-items: center;
}

.item {
  position: relative;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #303030;
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
}

.item:hover {
  color: #303030;
}

.item:not(:first-child) {
  margin-left: 24px;
}

.itemArrow:hover:after {
  transform: rotate(180deg);
}

.itemArrow {
  margin-right: 24px;
}

.itemArrow:after {
  content: "";
  position: absolute;
  bottom: calc(50% - 12px);
  left: calc(100% + 4px);
  display: inline-block;
  width: 24px;
  height: 24px;
  transition: all ease-in-out 0.4s;
  background: url("../../assets/icons/arrow_down.svg") 0 0 / auto auto no-repeat;
}

/* .item:hover .dropDownMenu {
   display: block;
} */

.item .dropDownMenu {
  display: none;
  position: absolute;
  top: 100%;
  left: -16px;
  font-weight: bold;
  background: #ffffff;
  color: black;
  padding: 16px;
  border-radius: 16px;
  z-index: 999;
}

.dropDownMenu.dropDownMenuActive {
  display: block;
}

.mobilMenu {
  display: none;
}

@media screen and (max-width: 1225px) {
  .settingsContainer {
    display: none;
  }

  .mainMenu {
    display: none;
  }

  .mobilMenu {
    display: block;
    padding-bottom: 2px;
    border-bottom: 2px solid #303030;
  }
}

/* @media screen and (max-width: 1225px) {
   .backBtn {
      display: block;
      text-align: right;
      color: #FFEC00;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.32px;
      text-decoration: underline;
      margin: 30px 0;
   }

   .mobilHotLine {
      margin-top: 30px;
      display: block;
   }

   .mobilMenu {
      color: #303030;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.32px;
      text-decoration: underline;
   }

   .settingsContainer {
      display: none;
   }

   .mainMenu {
      position: absolute;
      top: -100px;
      left: 0;
      border-radius: 0;
      width: 100%;
      height: 100vh;
      z-index: 1000;
      display: none;
   }

   .mainMenu.active {
      display: block;
   }

   .itemsContainer {
      width: 390px;
      margin: 30px auto 0 auto;
      display: flex;
      justify-content: center;
      align-items: end;
      flex-direction: column;
   }

   .itemArrow {
      margin-right: 0;
   }

   .itemArrow:after {
      transform: rotate(-90deg);
   }

   .item {
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
   }

   .item:not(:first-child) {
      margin: 12px 0 0 0;
   }

   .item .dropDownMenu {
      width: 100%;
      height: 100%;
      top: 100%;
      left: -16px;
      background: #212121;
      color: #FFFFFF;
      padding: 16px;
      border-radius: 16px;
   }
} */

.linkMenu {
  display: block;
  color: #303030;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: normal;
}

.linkMenu:hover {
  color: #b0b0b0;
}

.linkMenu:not(:first-child) {
  margin-top: 16px;
}

.searchBlock {
  display: flex;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #808080;
  cursor: pointer;
  padding: 8px 0px;
}

/* .searchBlock::after {
   content: '';
   position: absolute;
   left: 0;
   background: url('../../assets/icons/search.svg') 0 0/auto auto no-repeat;
   width: 24px;
   height: 24px;
} */

/* .search {
   width: 49px;
   height: 24px;
   background: transparent;
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   color: white;
} */
