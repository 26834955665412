.inputContainer {
   position: relative;
   display: flex;
   gap: 8px;
   padding: 16px 0px 16px 0px;
   border-bottom: 1px solid #ededed;
}

img.img {
   width: 24px;
   height: 24px;
}

.inputSearch {
   outline: none;
   border: none;
   font-size: 16px;
   line-height: 24px;
   width: 100%;
}

.clearX {
   position: absolute;
   top: calc(52px - 50%);
   right: 0;
   cursor: pointer;
}

.inputSearch:focus {
   outline: none;
   border: none;
}

input::placeholder {
   font-size: 16px;
   line-height: 24px;
}

.btnRowContainer {
   display: flex;
   gap: 10px;
}

.btnFilter {
   border-radius: 40px;
   padding: 4px 16px;
   cursor: pointer;
}

.btnFilter.active {
   background: #F6F6F6;
}

.resultContainer>* {
   padding: 30px 0;
   border-bottom: 1px solid #EDEDED;
}