.subtitle {
   color: #212121;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: -0.56px;
}

.charter {
   text-decoration: none;
   display: flex;
   align-items: center;
   overflow: hidden;
   color: #303030;
   text-overflow: ellipsis;
   text-wrap: nowrap;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.32px;
}

.charterIcon {
   margin-right: 24px;
   border-radius: 12px;
   background: #E13D34;
   padding: 12px;
}

.datePublication {
   color: #808080;
   text-align: right;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.28px;
}

.gimn {
   width: 100%;
}

.imgContainer {
   overflow: hidden;
   border-radius: 24px;
   width: 233px;
   height: 140px;
}

.imgContainer img {
   width: 100%;
}

.flexContainer {
   display: flex;
   justify-content: space-between;
}

.textContainer {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.titleCard {
   color: #303030;
   font-size: 18px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.36px;
}

.textBodyCard {
   width: 607px;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

@media screen and (max-width: 1370px) {
   .flexContainer {
      flex-direction: column;
      align-items: center;
   }

   .textContainer {
      margin-top: 32px;
   }

   .btnCard {
      margin-top: 32px;
   }

   .textBodyCard {
      width: 100%;
   }
}

@media screen and (max-width: 745px) {
   .titleCard {
      font-size: 24px;
   }

   .flexContainer {
      align-items: start;
   }
}