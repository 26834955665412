.flexContainer {
   display: flex;
   justify-content: space-between;
   gap: 20px;
}

.flexContainer>* {
   width: 50%;
}

.imgContainer {
   height: 200px;
   border-radius: 12px;
   overflow: hidden;
}

.imgContainer * {
   object-fit: cover;
   width: 100%;
   height: 100%;
}

.description {
   display: block;
   color: #8291A1;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: -0.28px;
   text-decoration: none;
}

.description:not(:first-child) {
   margin-top: 4px;
}

@media screen and (max-width: 900px) {
   .flexContainer {
      flex-direction: column;
   }

   .flexContainer>* {
      width: 100%;
   }

   .imgContainer {
      margin-top: 40px;
   }
}